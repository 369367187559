(function($) {
    var config = {
        start: {
            container: '#startCategories',
            categoryLink: '#startCategories .categories a',
            firstScroll: '#content'
        },
        header: {
            container: 'header',
            nav: 'header nav',
            logo: 'header .logo',
            hide: 'header .slogan',
            move: '#home',
            size: {
                small: '80',
                normal: '115'
            },
            dropdown: 'nav .dropdown,nav .dropup'
        },
        scrollTop: {
            button: 'scroll-top',
            target: 'body'
        },
        slider: '.slick',
        marquee: {
            container: '.marquee-cont',
            time: 10 //time required for full loop
        },
        news: {
            items: {
                link: '#news .news-side-item a',
                html: '#news article'
            },
            pagination: {
                link: '#news .pagination a',
                html: '#news aside .items'
            }
        },
        tooltip: '.product-benefits img',
        cartTabs: '.add-to-cart ul a',
        window: {
            height: $(window).height(),
            breakpoint: {
                md: 991
            }
        }
    };

    $(document).ready(function() {

        //named a
        $('a[href^="#"]').click(function(e) {
            e.preventDefault();
            //find item
            var target = $(this).data('target') ? $(this).data('target') : $(this).attr('href');

            if (target.length) {
                //history.pushState(null, null, $(this).attr('href'));
                scrollTo($(target));
            }
        });

        //slider
        $(config.slider).slick({
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            dots: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        arrows: false
                    }
                },
                {
                    breakpoint: 610,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });

        //tooltips
        $(config.tooltip).popover({
            placement: 'bottom',
            trigger: 'hover focus'
        });

        $(config.cartTabs).click(function (e) {
            e.preventDefault()
            $(this).tab('show');
        })

        //ajax in news
        //pagination
        $(document).on('click', config.news.pagination.link, function(e) {
            e.preventDefault();
            var pageLink = $(this);
            $(config.news.pagination.html).slideUp(function() {
                $.ajax({
                    url: '/wp-admin/admin-ajax.php',
                    type: 'post',
                    data: {
                        action: 'newsPagination',
                        page: pageLink.attr('data-page')
                    },
                    success: function (data) {
                        $(config.news.pagination.link).removeClass('active');
                        pageLink.addClass('active');
                        $(config.news.pagination.html).html(data).slideDown();
                    }
                });
            });
        }).on('click', config.news.items.link, function(e) {
            e.preventDefault();
            var itemLink = $(this);
            $(config.news.items.html).fadeOut(function() {
                $.ajax({
                    url: '/wp-admin/admin-ajax.php',
                    type: 'post',
                    data: {
                        action: 'newsItem',
                        post: itemLink.attr('data-post')
                    },
                    success: function (data) {
                        $(config.news.items.html).html(data).fadeIn();
                    }
                });
            });
        });

        $('.quantity-btn').click(function () {
            var increment = parseInt($(this).data('increment'));
            var currentValue = parseInt($('input[name="quantity"]').val());
            var targetValue = currentValue + increment;
            if (targetValue > 0) {
                $('input[name="quantity"]').val(targetValue);
            }
        });
    });

    $(window).resize(function () {

    }).load(function() {
        marquee({});
        header();
        observer();
        counters();
        });

    function header() {
        var header = $(config.header.container);
        var navHeight = parseInt($(config.header.nav).height());
        var target = $(config.header.container).next();
        var targetMarginTop = parseInt(target.css('margin-top'));
        target.css('margin-top', navHeight + targetMarginTop + 'px');

        $(window).scroll(function () {
           if ( $(this).scrollTop() > 50 ) {
               header.addClass('fixed');
           } else {
               header.removeClass('fixed');
           }
        });
    }

    function marquee(d)
    {
        if (d.time === undefined) {
            d.time = config.marquee.time;
        }

        if (d.container === undefined) {
            d.container = config.marquee.container;
        }

        if (d.loop === undefined) {
            d.loop = $(d.container);
        }

        if (d.firstRun !== false) {
            d.loop.append(d.loop.html());
            d.firstRun = false;
        }

        if (d.reset === undefined) {
            d.reset = -(d.loop.width()/2);
        }

        if (d.animTime === undefined) {
            d.animTime = d.time*(-d.reset);
        }

        d.loop.css('left', 0);
        d.loop.animate({left: d.reset}, d.animTime, 'linear', function() {
            marquee(d);
        });

    }

    function scrollTo(target) {
        $('html, body').animate({
            scrollTop: target.offset().top
        }, 500, );
    }

    function observer() {
        $('.observe').each(function () {
            if ($(window).scrollTop() > $(window).height() + $(this).height() ){
                $(this).addClass('invert-delay');
            }
        });


        $(window).scroll(function () {

            $('.observe:not(.no-animate)').each(function () {
                if ($(this).offset().top < ($(window).scrollTop() + ($(window).height() * .75)) && $(this).offset().top + $(this).height() > $(window).scrollTop()) {
                    $(this).addClass('intersecting');

                }
            });
        });
    }

    function counters() {
        $(window).scroll(function () {
            let counters = document.querySelectorAll('.stat');
            for (let index = 0; index < counters.length; index++) {
                const element = counters[index];
                if (element.classList.contains('intersecting')) {
                    initOdometer(element.querySelector('.odometer'));
                }
            }
        });

        function initOdometer(elem) {
            var meter;
            meter = new Odometer({
            el: elem,
            value: 0,

            // Any option (other than auto and selector) can be passed in here
            format: ''
            });

            meter.update(elem.dataset.max);
        }
    }

})(jQuery);
